import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import '../Css/Call.css';


export default function Call() {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);

    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
        setLanguage(lang);
    };
    return (
        <>
            <div className="call">
                <div className="inner">
                    <div className="main">
                            <h2 className={i18n.language === 'ar' ? 'arb' : 'eng'}>
                                {t('answer p1')}
                            </h2>
                        
                    </div>

                    <div className="box">
                        <img src="../assets/hero/png/box.png" alt="" />
                    </div>
                </div>

            </div>
        </>
    )
}
