import React from 'react'
import Hero from '../Components/Hero';
import Who from '../Components/Who.js';
import Mission from '../Components/Mission.js';
import Call from '../Components/Call.js';
import Service from '../Components/Service.js';
import Intro from '../Components/Intro.js';
import About from '../Components/About.js';
import Contect from '../Components/Contect.js';

export default function Home() {
    return (
        <>
            <Hero />
            <Intro />
            <Service />
            <Mission />
            <Who />
            <Call />
            <Contect />
            <About />
        </>
    )
}
