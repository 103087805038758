import { FaWhatsapp } from "react-icons/fa";
import React from 'react';
import '../Css/Whatsapp.css';

export default function
  () {
  return (
    <div>
      <div className='whatsApp'>
        <div className="main">
          <button className="logo" onClick={() => window.open("https://api.whatsapp.com/send?phone=971547640501", "_blank")}>
            <FaWhatsapp className="whatsapp-icon" />
          </button>

        </div>
      </div>
    </div>
  )
}
