import { useTranslation } from 'react-i18next';
import '../Css/About.css';

import React, { useState } from 'react'
import { Fade, Zoom } from 'react-awesome-reveal';

export default function () {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);

    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
        setLanguage(lang);
    };

    return (
        <>
            <div className="about">
                <div className="border-bottom">

                </div>
                <div className="images">
                    <img src="../assets/about/01.png" alt="" />
                </div>
                <div className="content">
                    <div className="main">
                        <Fade duration={3000}>
                            <h1 className={i18n.language === 'ar' ? 'arb' : ''}>
                                {t('welcome subheading')}
                            </h1>
                        </Fade>
                        <div className="border">
                        </div>
                        <div className="dis">
                            <p className={i18n.language === 'ar' ? 'arb' : ''}>
                                {t('welcome details')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
