import { useTranslation } from 'react-i18next';
import '../Css/Contect.css';
import React, { useState } from 'react'
import { IoMdCall } from "react-icons/io";
import { HiOutlineMail } from "react-icons/hi";
import { BsInstagram } from "react-icons/bs";
import { Fade } from 'react-awesome-reveal';

export default function () {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);

    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
        setLanguage(lang);
    };
    return (
        <div className="contact">
            <div className="main">
                <div className="dis">
                    <Fade duration={2000}>
                        <h1 className={i18n.language === 'ar' ? 'arb' : ''}>
                            {t('contact')}
                        </h1>
                    </Fade>
                    <div className="border">
                    </div>
                    <p className={i18n.language === 'ar' ? 'arb' : ''}>
                        {t('contact p1')}
                    </p>
                    <div className={i18n.language === 'ar' ? 'contact-details arb' : 'contact-details'}>
                        <a href="tel:971547640501">
                            <div className="item">
                                <IoMdCall className='icon cal' />
                                <p>
                                    +971 54 764 0501
                                </p>
                            </div>
                        </a>
                        <a href="mailto:alsuhaaexpressdelivery@gmail.com">
                            <div className="item">
                                <HiOutlineMail className='icon email' />
                                <p>alsuhaaexpressdelivery@gmail.com</p>
                            </div>
                        </a>
                        <a href="https://www.instagram.com/alsuhaa_expressdeliveryservice/">
                            <div className="item">
                                <BsInstagram className='icon' />
                                <p>alsuhaa_expressdeliveryservice</p>
                            </div>
                        </a>

                    </div>
                </div>

            </div>
            <div className="border-bottom">

            </div>
        </div>
    )
}
