import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import '../Css/Who.css';
import { Fade, Zoom } from 'react-awesome-reveal';

export default function Who() {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);

    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
        setLanguage(lang);
    };
    return (
        <>
            <div className="who">
                <div className="who-bg">
                    {/* <img className='who-gray' src="../assets/hero/png/maingray.png" alt="" />
                    <img className='who-gray-1' src="../assets/hero/png/maingray.png" alt="" />
                    <img className='who-gray-2' src="../assets/hero/png/mainred.png" alt="" />
                    <img className='who-gray-3' src="../assets/hero/png/mainred.png" alt="" />
                    <img className='who-gray-4' src="../assets/hero/png/mainred.png" alt="" /> */}
                    <div className="main">
                        <h1 className={i18n.language === 'ar' ? 'arb' : ''}>
                            {t('q1 heeaing')}
                        </h1>
                        <div className="border">
                        </div>
                        <div className="list">
                            <div className="item ">
                                <div className="img">
                                    <img src="../assets/who/01.jpg" alt="" />
                                </div>
                                    <div className="dis">
                                        <h2 className={i18n.language === 'ar' ? 'arb' : ''}>
                                            {t('answer 1 heading')}
                                        </h2>
                                        <p className={i18n.language === 'ar' ? 'arb' : ''}>
                                            {t('answer 1 details')}
                                        </p>
                                    </div>
                            </div>
                            <div className="item">
                                <div className="img">
                                    <img src="../assets/who/02.jpg" alt="" />
                                </div>
                                    <div className="dis">
                                        <h2 className={i18n.language === 'ar' ? 'arb' : ''}>
                                            {t('answer 2 heading')}
                                        </h2>
                                        <p className={i18n.language === 'ar' ? 'arb' : ''}>
                                            {t('answer 2 details')}
                                        </p>
                                    </div>
                            </div>
                            <div className="item ">
                                <div className="img">
                                    <img src="../assets/who/03.jpg" alt="" />
                                </div>
                                    <div className="dis">
                                        <h2 className={i18n.language === 'ar' ? 'arb' : ''}>
                                            {t('answer 3 heading')}
                                        </h2>
                                        <p className={i18n.language === 'ar' ? 'arb' : ''}>
                                            {t('answer 3 details')}
                                        </p>
                                    </div>
                            </div>
                            <div className="item ">
                                <div className="img">
                                    <img src="../assets/who/04.jpg" alt="" />
                                </div>
                                    <div className="dis">
                                        <h2 className={i18n.language === 'ar' ? 'arb' : ''}>
                                            {t('answer 4 heading')}
                                        </h2>
                                        <p className={i18n.language === 'ar' ? 'arb' : ''}>
                                            {t('answer 4 details')}
                                        </p>
                                    </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}
