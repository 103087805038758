import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import '../Css/Intro.css';
import { Fade } from 'react-awesome-reveal';

export default function Intro() {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);

    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
        setLanguage(lang);
    };
    return (
        <div className="intro">
            <div className="main">
                <Fade duration={1000}>
                    <p className={i18n.language === 'ar' ? 'arb' : ''}>
                        {t('service heading details')}
                    </p>
                </Fade>
            </div>

            <div className="border-bottom">

            </div>
        </div>
    )
}
