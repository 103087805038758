import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../Css/Mission.css';
import { Fade } from 'react-awesome-reveal';

export default function Mission() {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);


    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
        setLanguage(lang);
    };
    return (
        <>
            <div className="mission">
                <div className="border-top">
                </div>
                <div className="main">

                    <h1 className={i18n.language === 'ar' ? 'arb' : ''}>
                        {t('mission heading')}
                    </h1>

                    <div className="border">
                    </div>
                    <div className="dis">
                        <p className={i18n.language === 'ar' ? 'arb' : ''}>
                            {t('mission details')}
                        </p>
                    </div>
                    <div className="img">
                        <img src="../assets/mission/01.png" alt="" />
                    </div>

                </div>
                <div className="border-bottom">
                </div>
            </div>
        </>
    )
}
