import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../Pages/Home.js';
import OurService from '../Pages/OurService.js';
import ContactUs from '../Pages/ContactUs.js';

import Header from '../Components/Header.js';
import Footer from '../Components/Footer.js'
import Whatsapp from '../Components/Whatsapp.js'

export default function RoutesFunction() {
    return (

        <Router>
            <Header />
            {/* <ScrollToTop/> */}
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/our-service' element={<OurService />} />
                <Route path='/contact-us' element={<ContactUs />} />
            </Routes>
            <Whatsapp />
            <Footer />
        </Router>
    )
}
