import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import '../Css/Service.css';
import Reveal from 'react-awesome-reveal';
export default function Service() {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);

    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
        setLanguage(lang);
    };
    return (
        <div className="service">
            <div className="main">
                <h1 className={i18n.language === 'ar' ? 'arb' : ''}>
                    {t('our services heading')}
                </h1>
                <div className="border">
                </div>
                <div className="list">

                    <div className="item pack">
                        <div className="img">
                            <img src="../assets/servies/pack.png" alt="" />
                        </div>

                        <div className="content">
                            <h2 className={i18n.language === 'ar' ? 'arb' : ''}>
                                {t('our services subheading 2')}
                            </h2>
                            <p className={i18n.language === 'ar' ? 'arb' : ''}>
                                {t('our services subheading 2 details')}
                            </p>

                        </div>
                    </div>
                    <div className="item flower right">
                        <div className="img">
                            <img src="../assets/servies/flower.png" alt="" />
                        </div>
                        <div className="content">

                            <h2 className={i18n.language === 'ar' ? 'arb' : ''}>
                                {t('our services subheading 3')}
                            </h2>
                            <p className={i18n.language === 'ar' ? 'arb' : ''}>
                                {t('our services subheading 3 details')}
                            </p>
                        </div>
                    </div>
                    <div className="item food">
                        <div className="img">
                            <img src="../assets/servies/food.png" alt="" />
                        </div>
                        <div className="content">
                            <h2 className={i18n.language === 'ar' ? 'arb' : ''}>
                                {t('our services subheading 1')}
                            </h2>
                            <p className={i18n.language === 'ar' ? 'arb' : ''}>
                                {t('our services subheading 1 details')}
                            </p>
                        </div>
                    </div>
                    <div className="item pet right">
                        <div className="img">
                            <img src="../assets/servies/pet.png" alt="" />
                        </div>
                        <div className="content">
                            <h2 className={i18n.language === 'ar' ? 'arb' : ''}>
                                {t('our services subheading 4')}
                            </h2>
                            <p className={i18n.language === 'ar' ? 'arb' : ''}>
                                {t('our services subheading 4 details')}
                            </p>
                        </div>

                    </div>

                </div>
            </div>

        </div>
    )
}
