

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../Css/Hero.css';

export default function Hero() {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);

    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
        setLanguage(lang);
    };

    const [imageIndex, setImageIndex] = useState(0);

    useEffect(() => {
      const headerBackgrounds = document.querySelectorAll(".hero-bg-img img");
  
      const intervalId = setInterval(() => {
        headerBackgrounds[imageIndex].classList.remove("showings");
  
        setImageIndex(prevIndex => {
          let newIndex = prevIndex + 1;
          if (newIndex >= headerBackgrounds.length) {
            newIndex = 0;
          }
          headerBackgrounds[newIndex].classList.add("showings");
          return newIndex;
        });
      }, 4000);
  
      return () => clearInterval(intervalId);
    }, [imageIndex]);

    return (
        <>
            <div className="hero">
                <div className="heropart">
                    <img className='gray' src="../assets/hero/png/maingray.png" alt="" />
                    <div className="hero-bg">
                        <div className="hero-bg-img">
                            <img className='background showings' src="../assets/hero/png/per-2.png" alt="" />
                            <img className='background ' src="../assets/hero/png/per-3.png" alt="" />
                        </div>
                        <img className='red' src="../assets/hero/png/mainred.png" alt="" />
                        <div className="bottom-border">
                        </div>
                        <div className="content">
                            <div className="main">
                                {/* <h1 style={{
                                    textAlign: i18n.language === 'ar' ? 'right' : 'left',
                                    fontWeight: i18n.language === 'ar' ? '600' : '700',
                                    fontFamily: i18n.language === 'ar' ? '"Noto Kufi Arabic", sans-serif' : 'Poppins,sans-serif'
                                }}>
                                    {t('top headding')}
                                </h1> */}

                                <h1 className={i18n.language === 'ar' ? 'arb-sub' : 'eng-sub'}>
                                    {t('service heading')}
                                </h1>
                                <h1 className={i18n.language === 'ar' ? 'arb' : ''}>
                                    {t('service heading 2')}
                                </h1>

                                {/* <p style={{
                                    fontWeight: i18n.language === 'ar' ? '500' : '400',
                                    fontSize: i18n.language === 'ar' ? '21px' : '22px',
                                    fontFamily: i18n.language === 'ar' ? '"Noto Kufi Arabic", sans-serif' : 'Poppins,sans-serif'
                                }}>
                                    {t('dis')}
                                </p> */}

                                <h2 className={i18n.language === 'ar' ? 'arb' : ''}>
                                    {t('service heading inform')}
                                </h2>

                                {/* <div className="button-part" style={{
                                    justifyContent: i18n.language === 'ar' ? 'end' : 'start',
                                }}>
                                    <button style={{
                                        fontWeight: i18n.language === 'ar' ? '600' : '700',
                                        fontSize: i18n.language === 'ar' ? '18px' : '16px',
                                        fontFamily: i18n.language === 'ar' ? '"Noto Kufi Arabic", sans-serif' : 'Poppins,sans-serif'
                                    }}>
                                        {t('top btn')}
                                    </button>
                                    <button className={i18n.language === 'ar' ? 'arb' : 'eng'}>
                                        {t('top btn')}
                                    </button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            
            </div >
        </>
    )
}


