import React, { useState } from 'react';
import '../Css/Footer.css';
import { useTranslation } from 'react-i18next';
import { HiOutlineMail } from 'react-icons/hi';
import { BsInstagram } from 'react-icons/bs';
import { IoMdCall } from 'react-icons/io';

export default function Footer() {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);


  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };
  return (
    <>
      <div className="footer">
        <div className="main">
          <div className="logo">
            <a href="/">
              <img src="../assets/logo/logo.png" alt="" />
            </a>


          </div>
          <div className="quick">
            <h2>
              {t('quick')}
            </h2>
            <div className="border">

            </div>
            <ul>
              <li className={i18n.language === 'ar' ? 'arb' : ''}>
                <a href="/">
                  {t('home')}
                </a>

              </li>
              <li className={i18n.language === 'ar' ? 'arb' : ''}>
                <a href="/our-service">
                  {t('our services')}
                </a>
              </li>
              <li className={i18n.language === 'ar' ? 'arb' : ''}>
                <a href="/contact-us">
                  {t('Contact Us')}
                </a>
              </li>
            </ul>
          </div>

          <div className="contactus">
            <h2>
              {t('Contact Us')}

            </h2>
            <div className="border">

            </div>
            <div className={i18n.language === 'ar' ? 'contact-details arb' : 'contact-details'}>
              <a href="tel:+97154760501">
                <div className="item">
                  <IoMdCall className='icon cal' />
                  <p>
                    +971 54 764 0501
                  </p>
                </div>
              </a>
              <a href="mailto:alsuhaaexpressdelivery@gmail.com">
                <div className="item">
                  <HiOutlineMail className='icon email' />
                  <p>alsuhaaexpressdelivery@gmail.com</p>
                </div>
              </a>
              <a href="https://www.instagram.com/alsuhaa_expressdeliveryservice/">
                <div className="item">
                  <BsInstagram className='icon' />
                  <p>alsuhaa_expressdeliveryservice</p>
                </div>
              </a>

            </div>
          </div>
        </div>

      </div>
      <div className="footer-down">
        <div className='main'>
          <p className={i18n.language === 'ar' ? 'arb' : ''} >   {t('copy reight')}</p>
        </div>
      </div>
    </>
  )
}

