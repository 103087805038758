import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import '../Css/Header.css';

export default function Header() {
  const [isChecked, setIsChecked] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [animationClass, setAnimationClass] = useState('no-animation');

  const change = () => {
    setAnimationClass('');
  };

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };

  const toggleLanguage = () => {
    setIsChecked(prevState => !prevState);
    const newLanguage = isChecked ? 'ar' : 'en';
    i18n.changeLanguage(newLanguage);
  };


  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);




  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(true);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  return (
    <div className={scrolled ? 'header scrolled' : 'header'}>
      <div className={i18n.language === 'ar' ? 'main arb' : 'main'}>
        <a href="/">
          <div className="logo">
            <img src="../assets/logo/logo.png" alt="" />
          </div>
        </a>

        <div className="nav">
          <div className="buttons">
            <button
              onClick={() => i18n.changeLanguage('ar')}
              className={i18n.language === 'ar' ? 'act' : ''}
            >
              Ar
            </button>
            <button
              onClick={() => i18n.changeLanguage('en')}
              className={i18n.language === 'en' ? 'act' : ''}

            >
              En
            </button>
          </div>
          <div className='menu-icon' onClick={handleClick}>
            <FontAwesomeIcon className='icon' icon={click ? faTimes : faBars} />
          </div>
          <nav className={click ? 'activ' : ''}>
            <ul>
              <li className={i18n.language === 'ar' ? 'arb' : ''}>
                <a href="/">
                  {t('home')}
                </a>

              </li>
              <li className={i18n.language === 'ar' ? 'arb' : ''}>
                <a href="/our-service">
                  {t('our services')}
                </a>
              </li>
              <li className={i18n.language === 'ar' ? 'arb' : ''}>
                <a href="/contact-us">
                  {t('Contact Us')}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
}
