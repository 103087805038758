import '../Css/ContactUs.css';
import React, { useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import { useTranslation } from 'react-i18next';
import { BsInstagram } from 'react-icons/bs';
import { HiOutlineMail } from 'react-icons/hi';
import { IoMdCall } from 'react-icons/io';


export default function ContactUs() {

    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);

    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
        setLanguage(lang);
    };
    return (
        <>
            <div className="contact-header">

                <div className="heading">
                    <h1 className={i18n.language === 'ar' ? 'arb' : ''}>
                        {t('contact')}
                    </h1>
                </div>
            </div>
            <div className="page-border">

            </div>
            <div className="contact right int">
                <div className="main">
                    <div className="dis">
                        <Fade duration={2000}>
                            <h1 className={i18n.language === 'ar' ? 'arb' : ''}>
                                {t('contact')}
                            </h1>
                        </Fade>
                        <div className="border">
                        </div>
                        <p className={i18n.language === 'ar' ? 'arb' : ''}>
                            {t('contact p1')}
                        </p>
                    </div>

                </div>
            </div>
            <div className="contact pho">
                <div className="main">
                    <div className="dis">
                        <Fade duration={2000}>
                            <h1 className={i18n.language === 'ar' ? 'arb' : ''}>
                                {t('contact phone')}
                            </h1>
                        </Fade>
                        <div className="border">
                        </div>
                        <p className={i18n.language === 'ar' ? 'arb' : ''}>
                            {t('contact phone details')} <a href="tel:+971547640501">[+971547640501]</a> {t('contact phone details1')}
                        </p>
                    </div>

                </div>
            </div>
            <div className="contact right ema">
                <div className="main">
                    <div className="dis">
                        <Fade duration={2000}>
                            <h1 className={i18n.language === 'ar' ? 'arb' : ''}>
                                {t('contact email')}
                            </h1>
                        </Fade>
                        <div className="border">
                        </div>
                        <p className={i18n.language === 'ar' ? 'arb' : ''}>
                            {t('contact email details')} <a href="mailto:alsuhaaexpressdelivery@gmail.com">[alsuhaaexpressdelivery@gmail.com]</a> {t('contact email details1')}
                        </p>
                    </div>

                </div>
            </div>
            <div className="contact insta">
                <div className="main">
                    <div className="dis">
                        <Fade duration={2000}>
                            <h1 className={i18n.language === 'ar' ? 'arb' : ''}>
                                {t('contact media')}
                            </h1>
                        </Fade>
                        <div className="border">
                        </div>
                        <p className={i18n.language === 'ar' ? 'arb' : ''}>
                            {t('contact media details')}<a href="https://www.instagram.com/alsuhaa_expressdeliveryservice/"> [@alsuhaa_expressdeliveryservice] </a> {t('contact media details1')}
                        </p>
                    </div>

                </div>
            </div>
            <div className="page-border green">

            </div>

        </>
    )
}
